import React from 'react'
import { Text } from '../../elements/Text/Text'
import { theme } from '@/src/stitches.config'

export interface HandwrittenProps {
    text: string
    transform?: string
    size?: number
    marginBottom?: string | number
}

const HandwrittenText = ({
    text,
    transform,
    size = 6,
    marginBottom = '0.25rem',
}: HandwrittenProps) => {
    return (
        <Text
            variant={'handWritten'}
            data-testid={'handwritten'}
            size={size}
            css={{
                transform: transform ?? 'translate(2rem, -8ex) rotate(-11deg)',
                maxWidth: '14rem',
                textAlign: 'center',
                color: `${theme.colors.secondary} !important`,
                lineHeight: '1.5rem',
                letterSpacing: '.04rem',
                marginBottom: marginBottom,
            }}
        >
            {text}
        </Text>
    )
}

export default HandwrittenText
